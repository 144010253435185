import _ from 'lodash'
import {Row, Col, Tabs, Tab, Form, Button} from 'react-bootstrap'
import {useNavigate, useSearchParams} from 'react-router-dom'
import Layout from '../Layout'
import {fetchJSON} from '../lib/fetch'
import {useForm} from '../lib/hooks'
import {Session} from '../lib/models'
import {EN, ZH, Translate, useUserLanguage} from '../lib/translate'

export default function Login() {
  const navigate = useNavigate()
  const {translate, translateText} = useUserLanguage()
  const [searchParams, setSearchParams] = useSearchParams()

  const onLoggedHandler = (session: Session) => {
    localStorage.setItem('accountName', session.accountName)
    navigate('/console/dashboard')
  }

  const onTabSelectHandler = (key: string | null) => {
    setSearchParams({tab: key ?? ''})
  }

  const activeTab = searchParams.get('tab') || 'login'

  return <Layout title={translateText({en: 'Login or Signup', zh: '登录或注册'})}>
    <Row className='justify-content-center pt-2'>
      <Col className='col col-xl-10'>
        <Tabs transition={false} onSelect={onTabSelectHandler} activeKey={activeTab} id='login-tabs'>
          {activeTab !== 'reset-password' && <Tab className='pt-4' eventKey='signup' title={translate(<><EN>Sign up</EN><ZH>注册</ZH></>)}>
            <SignupTab onLogged={onLoggedHandler} />
          </Tab>}
          {activeTab !== 'reset-password' && <Tab className='pt-4' eventKey='login' title={translate(<><EN>Login</EN><ZH>登录</ZH></>)}>
            <LoginTab onLogged={onLoggedHandler} />
          </Tab>}
          {activeTab !== 'reset-password' && <Tab className='pt-4' eventKey='reset-by-email' title={translate(<><EN>Reset by Email</EN><ZH>重置密码</ZH></>)}>
            <ResetByEmailTab />
          </Tab>}
          {activeTab === 'reset-password' && <Tab className='pt-4' eventKey='reset-password' title={translate(<><EN>Reset Password</EN><ZH>重置密码</ZH></>)}>
            <ResetPasswordTab onLogged={onLoggedHandler} />
          </Tab>}
        </Tabs>
      </Col>
    </Row>
  </Layout>
}

interface SignupOrLoginProps {
  onLogged(session: Session): void
}

function SignupTab({onLogged}: SignupOrLoginProps) {
  const {submitting, changeHandler, submitHandler} = useForm({
    email: '',
    name: '',
    password: ''
  }, async formData => {
    await fetchJSON('/api/accounts', {
      method: 'POST',
      body: formData
    })

    const session = await fetchJSON<Session>('/api/sessions/login', {
      method: 'POST',
      body: _.pick(formData, 'email', 'password')
    })

    onLogged(session)
  })

  return <Translate>
    <Form onSubmit={submitHandler}>
      <Form.Group as={Row} className='mb-3' controlId='signup-email'>
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='email' type='email' required onChange={changeHandler} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3' controlId='signup-name'>
        <Form.Label column sm={2}>
          <EN>Name</EN><ZH>名字</ZH>
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='name' required onChange={changeHandler} />
          <Form.Text muted><EN>This name will be used in git commit.</EN><ZH>将被显示在 Git 的提交记录中。</ZH></Form.Text>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3' controlId='signup-password'>
        <Form.Label column sm={2}>
          <EN>Password</EN><ZH>密码</ZH>
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='password' type='password' required onChange={changeHandler} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3'>
        <Col sm={{span: 10, offset: 2}} md={{span: 6, offset: 2}} lg={{span: 4, offset: 2}}>
          <Button type='submit' variant='primary' disabled={submitting}><EN>Sign up</EN><ZH>注册</ZH></Button>
        </Col>
      </Form.Group>
    </Form>
  </Translate>
}

function LoginTab({onLogged}: SignupOrLoginProps) {
  const {submitting, changeHandler, submitHandler} = useForm({
    email: '',
    password: ''
  }, async formData => {
    const session = await fetchJSON<Session>('/api/sessions/login', {
      method: 'POST',
      body: formData
    })

    onLogged(session)
  })

  return <Translate>
    <Form onSubmit={submitHandler}>
      <Form.Group as={Row} className='mb-3' controlId='login-email'>
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='email' type='email' required onChange={changeHandler} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3' controlId='login-password'>
        <Form.Label column sm={2}>
          <EN>Password</EN><ZH>密码</ZH>
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='password' type='password' required onChange={changeHandler} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3'>
        <Col sm={{span: 10, offset: 2}} md={{span: 6, offset: 2}} lg={{span: 4, offset: 2}}>
          <Button type='submit' variant='primary' disabled={submitting}><EN>Login</EN><ZH>登录</ZH></Button>
        </Col>
      </Form.Group>
    </Form>
  </Translate>
}

function ResetByEmailTab() {
  const {language, translateText} = useUserLanguage()
  const {submitting, changeHandler, submitHandler} = useForm({
    email: '',
    language: language
  }, async formData => {
    await fetchJSON<Session>('/api/sessions/password-reset', {
      method: 'POST',
      body: formData
    })

    alert(translateText({
      en: 'Email sent',
      zh: '邮件已发送'
    }))
  })

  return <Translate>
    <Form onSubmit={submitHandler}>
      <Form.Group as={Row} className='mb-3' controlId='reset-email'>
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='email' type='email' required onChange={changeHandler} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3'>
        <Col sm={{span: 10, offset: 2}} md={{span: 6, offset: 2}} lg={{span: 4, offset: 2}}>
          <Button type='submit' variant='primary' disabled={submitting}><EN>Send email</EN><ZH>发送邮件</ZH></Button>
        </Col>
      </Form.Group>

      <hr />
      <ul>
        <li>
          <EN>You will receive an email only if the email address is registered and no duplicate operation in the last 6 hours.</EN>
          <ZH>如果邮件地址填写正确且在过去 6 小时没有重复操作，你将会收到一封重置密码的邮件。</ZH>
        </li>
        <li>
          <EN>Click the link in the email to reset your password.</EN>
          <ZH>然后请点击邮件中的链接来重置密码。</ZH>
        </li>
      </ul>
    </Form>
  </Translate>
}

function ResetPasswordTab({onLogged}: SignupOrLoginProps) {
  const [searchParams] = useSearchParams()

  const {submitting, changeHandler, submitHandler} = useForm({
    password: ''
  }, async formData => {
    await fetchJSON<Session>(`/api/sessions/password-reset/${searchParams.get('token')}`, {
      method: 'POST',
      body: formData
    })

    const session = await fetchJSON<Session>('/api/sessions/login', {
      method: 'POST',
      body: {
        email: searchParams.get('email'),
        password: formData.password
      }
    })

    onLogged(session)
  })

  return <Translate>
    <Form onSubmit={submitHandler}>
      <Form.Group as={Row} className='mb-3' controlId='reset-email'>
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control plaintext readOnly defaultValue={searchParams.get('email') || ''} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3' controlId='reset-password'>
        <Form.Label column sm={2}>
          <EN>Password</EN><ZH>密码</ZH>
        </Form.Label>
        <Col sm={10} md={6} lg={4}>
          <Form.Control name='password' type='password' required onChange={changeHandler} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className='mb-3'>
        <Col sm={{span: 10, offset: 2}} md={{span: 6, offset: 2}} lg={{span: 4, offset: 2}}>
          <Button type='submit' variant='primary' disabled={submitting}><EN>Reset Password</EN><ZH>重置密码</ZH></Button>
        </Col>
      </Form.Group>
    </Form>
  </Translate>
}
