import React from 'react'
import {Link, NavLink, useLocation} from 'react-router-dom'
import {Container, NavDropdown} from 'react-bootstrap'
import {EN, Translate, ZH, useUserLanguage} from './lib/translate'
import {BsTranslate} from 'react-icons/bs'
import {useLoggedUser} from './lib/fetch'
import './Layout.css'
import {Helmet, HelmetProvider} from 'react-helmet-async'

export interface LayoutProps {
  title?: string
  description?: string
  article?: {author: string, publishedAt: string}
  children: React.ReactNode
}

export default function Layout({children, title, description, article}: LayoutProps) {
  const username = useLoggedUser()
  const location = useLocation()
  const {setLanguage, translateText} = useUserLanguage()

  const pageTitle = title ? `${title} | HostedBeans` : 'HostedBeans'

  const defaultDescription = translateText({
    en: 'Host your Beancount ledger with Git and Web UI (Fava) access',
    zh: '在线托管你的 Beancount 账本，通过 Git 和 Web UI 实现基于纯文本的复式记账协作'
  })

  return <HelmetProvider>
    <Container fluid='md'>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='description' content={description || defaultDescription} />
        <meta property='og:title' content={article ? title : pageTitle} />
        <meta property='og:type' content={article ? 'article' : 'website'} />
        {article && <meta property='og:article:author' content={article.author} />}
        {article && <meta property='og:article:published_time' content={article.publishedAt} />}
        <meta property='og:url' content={`https://www.hostedbeans.io${location.pathname}`} />
        <meta property='og:image' content='https://www.hostedbeans.io/logo192.png' />
        <meta property='og:site_name' content='HostedBeans' />
      </Helmet>

      <Translate>
        <header className='py-3 mb-3 border-bottom'>
          <div className='d-flex flex-wrap align-items-center justify-content-start'>
            <Link to='/' className='col-12 col-sm-auto text-center link-body-emphasis text-decoration-none'>
              <span className='fs-4 px-3'>HostedBeans</span>
            </Link>

            <ul className='nav col-auto me-auto justify-content-center'>
              <li><Link to='/pricing' className='nav-link px-2 link-body-emphasis'><EN>Pricing</EN><ZH>价格方案</ZH></Link></li>
              <li><Link to='/docs/getting-started' className='nav-link px-2 link-body-emphasis'><EN>Docs</EN><ZH>文档</ZH></Link></li>
              <li><Link to='/docs/faq' className='nav-link px-2 link-body-emphasis'><EN>FAQs</EN><ZH>常见问题</ZH></Link></li>
            </ul>

            <div className='nav col-auto ms-auto text-end'>
              <NavDropdown className='nav-item' title={<BsTranslate />}>
                <NavDropdown.Item onClick={() => setLanguage('en')}>English</NavDropdown.Item>
                <NavDropdown.Item onClick={() => setLanguage('zh')}>中文</NavDropdown.Item>
              </NavDropdown>
              {username && <NavLink to='/console/dashboard' className={({isActive}) => (isActive ? 'd-none' : 'nav-item btn btn-outline-primary')}><EN>Dashboard</EN><ZH>控制台</ZH></NavLink>}
              {!username && <NavLink to='/console/login' className={({isActive}) => (isActive ? 'd-none' : 'nav-item btn btn-outline-primary')}><EN>Login or Sign up</EN><ZH>登录或注册</ZH></NavLink>}
            </div>
          </div>
        </header>

        {children}

        <footer className='d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top'>
          <p className='col-12 col-sm-auto mb-0 text-center text-sm-start text-body-secondary'>HostedBeans since 2023</p>
          <div className='col-12 col-sm-auto text-center text-sm-end'>
            <span className='mx-1 text-body-secondary'>Contact us via</span>
            <a target='_blank' rel='noreferrer' href='mailto:feedback@hostedbeans.io' className='mx-1 text-body-secondary'>Email</a>
            <a target='_blank' rel='noreferrer' href='https://t.me/jysperm' className='mx-1 text-body-secondary'>Telegram</a>
            <a target='_blank' rel='noreferrer' href='https://twitter.com/jysperm' className='mx-1 text-body-secondary'>Twitter</a>
          </div>
        </footer>
      </Translate>
    </Container>
  </HelmetProvider>
}
