import React from 'react'
import _ from 'lodash'
import {Row, Card, Button, Form, Col, Alert, Badge, ButtonGroup, DropdownButton, Dropdown, Modal, ModalProps, Table, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap'
import Layout from '../Layout'
import {Ledger, Account, LedgerCollaboration} from '../lib/models'
import {ErrorGuard} from '../lib/components'
import {fetchJSON, useFetch} from '../lib/fetch'
import {alertError, confirmBefore} from '../lib/utils'
import {getPermissions, Permissions} from '../lib/plan'
import {useForm} from '../lib/hooks'
import './Dashboard.css'
import {useNavigate, Link} from 'react-router-dom'
import {BsCheckCircle, BsClipboard, BsClipboardCheck, BsHourglass, BsXCircle} from 'react-icons/bs'
import {EN, ZH, Translate, useUserLanguage} from '../lib/translate'

const plan2badgeBg = {
  free: 'secondary',
  pro: 'success',
  biz: 'primary'
}

const visibility2badgeBg = {
  private: 'secondary',
  public: 'info'
}

const role2badgeBg = {
  write: 'info',
  readonly: 'secondary'
}

const plan2text = {
  free: {en: 'Free'},
  pro: {en: 'Pro'},
  biz: {en: 'Biz', zh: '定制版'}
}

const visibility2text = {
  private: {en: 'Private', zh: '私有账本'},
  public: {en: 'Public', zh: '公开账本'}
}

const role2text = {
  write: {en: 'Read & Write', zh: '读写权限'},
  readonly: {en: 'Readonly',zh: '只读权限'}
}

const PermissionsContext = React.createContext<Permissions>({})

export default function Dashboard() {
  const {translateText} = useUserLanguage()

  const [ledgers, {error: ledgersError, reload: reloadLedgers}] = useFetch<Ledger[]>(['/api/ledgers'])
  const [account, {error: accountError, reload: reloadAccount}] = useFetch<Account>(['/api/accounts/self'])
  const [collaborations, {error: collaborationsError, reload: reloadCollaborations}] = useFetch<LedgerCollaboration[]>(['/api/collaborations'])

  const [createOrEditLedgerModal, setCreateOrEditLedgerModal] = React.useState<Ledger | boolean>(false)
  const [editingCollaborators, setEditingCollaborators] = React.useState<number | false>(false)
  const [copiedUrl, setCopiedUrl] = React.useState<string>('')

  const permissions = getPermissions(account, ledgers)

  const collaborationActions = (ledgerId: number, action: 'join' | 'leave') => {
    return async () => {
      try {
        await fetchJSON(`/api/collaborations/${ledgerId}/${action}`, {
          method: 'PUT'
        })

        reloadCollaborations()
        reloadLedgers()
      } catch (err) {
        alertError(err)
      }
    }
  }

  const deleteLedgerAction = (ledgerId: number) => {
    return async () => {
      try {
        await fetchJSON(`/api/ledgers/${ledgerId}`, {
          method: 'DELETE'
        })

        reloadLedgers()
      } catch (err) {
        alertError(err)
      }
    }
  }

  const selectThisHandler = (e: any) => {
    e.target.select()
  }

  return <Layout title={translateText({en: 'Dashboard', zh: '控制台'})}>
    <PermissionsContext.Provider value={permissions}>
      <Row className='px-3'>
        <Col className='clearfix'>
          <h2 className='my-4'>
            <EN>Ledgers</EN><ZH>所有账本</ZH>

            {collaborations && <OverlayTrigger trigger={!permissions.createLedger ? ['hover', 'focus'] : []} overlay={<Tooltip>{translateText({en: 'Upgrade Pro to create more ledgers', zh: '升级至 Pro 来创建更多账本'})}</Tooltip>}>
              <Button className='float-end' disabled={!permissions.createLedger} variant='primary' onClick={() => setCreateOrEditLedgerModal(true)}>
                <EN>New ledger</EN><ZH>新建账本</ZH>
              </Button>
            </OverlayTrigger>}
          </h2>
          <CreateOrEditLedgerModal ledger={createOrEditLedgerModal} onHide={() => setCreateOrEditLedgerModal(false)} needReload={reloadLedgers} />
          <ErrorGuard err={collaborationsError}>
            {_.filter(collaborations, {state: 'invited'}).map( collaboration => {
              return <Card key={collaboration.ledgerId} className='mb-3'>
                <Card.Header><EN>New Invitation</EN><ZH>新邀请</ZH></Card.Header>
                <Card.Body>
                  <Card.Text>
                    <EN><em>{collaboration.ownerEmail}</em> invited you join <em>{collaboration.ledgerName}</em></EN>
                    <ZH><em>{collaboration.ownerEmail}</em> 邀请你加入<em>{collaboration.ledgerName}</em> </ZH>
                    <Badge className='mx-1' bg={role2badgeBg[collaboration.role]}>{translateText(role2text[collaboration.role])}</Badge>
                  </Card.Text>
                  <ButtonGroup>
                    <Button variant='primary' onClick={collaborationActions(collaboration.ledgerId, 'join')}><EN>Accpet</EN><ZH>接受</ZH></Button>
                    <Button variant='light' onClick={collaborationActions(collaboration.ledgerId, 'leave')}><EN>Deny</EN><ZH>拒绝</ZH></Button>
                  </ButtonGroup>
                </Card.Body>
              </Card>
            })}
          </ErrorGuard>
          <ErrorGuard err={ledgersError}>
            {ledgers?.map( ledger => {
              const collaboration = _.find(collaborations, {ledgerId: ledger.id})
              const gitUrl = `git@hostedbeans.io:ledgers/${ledger.id}`

              const copyToClipboardHandler = () => {
                navigator.clipboard.writeText(gitUrl)
                setCopiedUrl(gitUrl)
              }

              return <Card key={ledger.id} className='mb-3'>
                <Card.Body>
                  <Card.Title as='h3'>
                    {ledger.name}
                    <small><Badge className='mx-2' bg={visibility2badgeBg[ledger.visibility]}>{translateText(visibility2text[ledger.visibility])}</Badge></small>
                  </Card.Title>
                  <InputGroup size='sm' className='mt-3 flex-nowrap'>
                    <InputGroup.Text as='label' htmlFor={`git-access-url-${ledger.id}`}>Git access</InputGroup.Text>
                    <Form.Control className='font-monospace' id={`git-access-url-${ledger.id}`} onClick={selectThisHandler} readOnly style={{width: `${gitUrl.length + 4}ch`, flex: '0 1 auto', userSelect: 'all'}} defaultValue={gitUrl} />
                    <Button className='text-nowrap' variant='outline-secondary' onClick={copyToClipboardHandler}>
                      {copiedUrl === gitUrl ? <BsClipboardCheck /> : <BsClipboard />} {copiedUrl === gitUrl ? 'Copied' : 'Copy'}
                    </Button>
                  </InputGroup>
                  <Card.Text>
                    <Form.Text muted>
                      <EN>Learn more about <Link to='/docs/manual#git-access'>Git access</Link>.</EN>
                      <ZH>进一步了解 <Link to='/docs/manual#git-access'>Git 访问</Link>。</ZH>
                    </Form.Text>
                  </Card.Text>
                  <ButtonGroup>
                    <Button href={`/ledgers/${ledger.id}`} target='_blank' variant='primary'><EN>Open Fava</EN><ZH>打开 Fava</ZH></Button>
                    {ledger.ownerId === account?.id && <Button variant='light' onClick={() => setEditingCollaborators(ledger.id)}><EN>Collaborators ...</EN><ZH>协作者 ...</ZH></Button>}
                    <DropdownButton as={ButtonGroup} variant='light' title=''>
                      {ledger.ownerId === account?.id && <Dropdown.Item onClick={() => setCreateOrEditLedgerModal(ledger)}><EN>Ledger settings</EN><ZH>修改设置</ZH></Dropdown.Item>}
                      {ledger.ownerId === account?.id && <Dropdown.Item className='danger' onClick={confirmBefore(deleteLedgerAction(ledger.id))}><EN>Delete this ledger</EN><ZH>删除账本</ZH></Dropdown.Item>}
                      {collaboration && <Dropdown.Item className='danger' onClick={confirmBefore(collaborationActions(ledger.id, 'leave'))}><EN>Leave this ledger</EN><ZH>退出账本</ZH></Dropdown.Item>}
                    </DropdownButton>
                  </ButtonGroup>
                </Card.Body>
                {(collaboration || (ledger.ownerId === account?.id && !_.isEmpty(ledger.collaborators))) && <Card.Footer className='text-muted'>
                  {ledger.ownerId === account?.id && !_.isEmpty(ledger.collaborators) && <Card.Text>
                    <EN>Shared to {ledger.collaborators?.length} collaborators</EN>
                    <ZH>已共享给 {ledger.collaborators?.length} 名协作者</ZH>
                  </Card.Text>}
                  {collaboration && <Card.Text>
                    <EN>Shared by <em>{collaboration.ownerEmail}</em></EN>
                    <ZH>由 <em>{collaboration.ownerEmail}</em> 共享</ZH>
                    &nbsp;<Badge bg={role2badgeBg[collaboration.role]}>{translateText(role2text[collaboration.role])}</Badge>
                  </Card.Text>}
                </Card.Footer>}
              </Card>
            })}
          </ErrorGuard>
          <LedgerCollaboratorsModal ledger={_.find(ledgers, {id: editingCollaborators}) as Ledger | undefined} onHide={() => setEditingCollaborators(false)} needReload={reloadLedgers} />
        </Col>
      </Row>
      <Row className='px-3'>
        <Col>
          <AccountSettings account={account} error={accountError} needReload={reloadAccount} />
        </Col>
      </Row>
      <Row className='px-3 mb-3'>
        <Col>
          <h2 className='my-4'><EN>My Plan</EN><ZH>我的方案</ZH></h2>
          <ErrorGuard err={accountError}>
            <Row className='gx-3 gy-2'>
              <Col md='auto'>{account?.plan && <Badge bg={plan2badgeBg[account.plan]}>{translateText(plan2text[account.plan])}</Badge>}</Col>
              <EN><Col md='auto'><p className='text-body-secondary'>Have a look on <Link to='/pricing'>Pricing</Link> page for other options.</p></Col></EN>
              <ZH><Col md='auto'><p className='text-body-secondary'>了解更多其他 <Link to='/pricing'>价格方案</Link>。</p></Col></ZH>
            </Row>
          </ErrorGuard>
        </Col>
      </Row>
    </PermissionsContext.Provider>
  </Layout>
}

function AccountSettings({account, error, needReload}: {account?: Account, error?: Error, needReload: () => void}) {
  const navigate = useNavigate()

  const {changed, submitting, changeHandler, submitHandler} = useForm({
    name: '',
    sshKeys: ''
  }, async formData => {
    await fetchJSON('/api/accounts/self', {
      method: 'PUT',
      body: _.pickBy(formData)
    })

    needReload()
  })

  const onLogoutHandler = async () => {
    try {
      await fetchJSON('/api/sessions/login/self', {
        method: 'DELETE'
      })

      navigate('/')
    } catch (err) {
      alertError(err)
    } finally {
      localStorage.removeItem('accountName')
      localStorage.removeItem('selectedLedger')
      localStorage.removeItem('selectedLedgerDetail')
    }
  }

  return <Translate>
    <h2 className='my-4'>
      <EN>Account Settings</EN><ZH>账号设置</ZH>

      <DropdownButton className='float-end' align='start' variant='light' title=''>
        <Dropdown.Item as={Button} onClick={onLogoutHandler}><EN>Logout</EN><ZH>退出登录</ZH></Dropdown.Item>
      </DropdownButton>
    </h2>
    <ErrorGuard err={error}>
      <Form onSubmit={submitHandler}>
        <Form.Group as={Row} className='mb-3' controlId='account-email'>
          <Form.Label column sm={3}>
            Email
          </Form.Label>
          <Col sm={9}>
            <Form.Control plaintext readOnly defaultValue={account?.email} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='account-name'>
          <Form.Label column sm={3}>
            <EN>Name</EN><ZH>名字</ZH>
          </Form.Label>
          <Col sm={9}>
            <Form.Control name='name' required defaultValue={account?.name} onChange={changeHandler} />
            <Form.Text muted><EN>This name will be used in git commit.</EN><ZH>这个名字会被显示在 Git commit 中。</ZH></Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='account-ssh-keys'>
          <Form.Label column sm={3}>
            <EN>SSH Keys</EN><ZH>SSH 密钥</ZH>
          </Form.Label>
          <Col sm={9}>
            <Form.Control as='textarea' name='sshKeys' className='font-monospace text-body-secondary' style={{fontSize: '0.75em'}} spellCheck='false' rows={3} defaultValue={account?.sshKeys} onChange={changeHandler} />
            <Form.Text muted>
              <EN>Put your SSH public keys here to enable Git access (one key per line), learn more about <Link to='/docs/manual#setup-ssh-keys'>Setup SSH keys</Link>.</EN>
              <ZH>输入你的 SSH 公钥来访问 Git，每行一个。进一步了解 <Link to='/docs/manual#setup-ssh-keys'>设置 SSH 密钥</Link>。</ZH>
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Col sm={{span: 9, offset: 3}}>
            <Button type='submit' variant='primary' disabled={!changed || submitting}><EN>Save</EN><ZH>保存</ZH></Button>
          </Col>
        </Form.Group>
      </Form>
    </ErrorGuard>
  </Translate>
}

function CreateOrEditLedgerModal({needReload, ledger, ...props}: ModalProps & {needReload: () => void, ledger?: Ledger | boolean}) {
  const {translateText} = useUserLanguage()
  const edtingLedger: Ledger | undefined = ledger === true ? undefined : ledger as Ledger

  const initialFormData = _.defaults(_.pick(ledger, 'name', 'visibility'), {
    name: '',
    visibility: 'private'
  })

  const {submitting, changeHandler, submitHandler} = useForm(initialFormData, async formData => {
    if (!edtingLedger) {
      await fetchJSON('/api/ledgers', {
        method: 'POST',
        body: formData
      })
    } else {
      await fetchJSON(`/api/ledgers/${edtingLedger.id}`, {
        method: 'PUT',
        body: formData
      })
    }

    needReload()
    props.onHide?.()
  })

  return <Translate>
    <Modal show={!!ledger} {...props} centered backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          {!edtingLedger && <><EN>Create Ledger</EN><ZH>创建账本</ZH></>}
          {edtingLedger && <><EN>Settings of {edtingLedger?.name}</EN><ZH>{edtingLedger?.name} 的设置</ZH></>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className='mb-3' controlId='create-name'>
            <Form.Label><EN>Name</EN><ZH>账本名字</ZH></Form.Label>
            <Form.Control name='name' autoFocus onChange={changeHandler} defaultValue={initialFormData.name} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label><EN>Visibility</EN><ZH>可见范围</ZH></Form.Label>
            <Form.Check type='radio' name='visibility' id='visibility-private' value='private' label={translateText({en: 'Private', zh: '私有账本'})} onChange={changeHandler} defaultChecked={initialFormData.visibility === 'private'} />
            <Form.Check type='radio' name='visibility' id='visibility-public' value='public' label={translateText({en: 'Public', zh: '公开账本'})} onChange={changeHandler} defaultChecked={initialFormData.visibility === 'public'} />
            <Form.Text muted>
              <EN>With caution, public ledgers allow everyone to access via Web UI, or clone via Git.</EN>
              <ZH>请注意：公开账本允许所有人通过 Web UI 访问或使用 <code>git clone</code>。</ZH>
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' disabled={!!submitting} onClick={submitHandler}>
          {!edtingLedger && <><EN>Create</EN><ZH>创建</ZH></>}
          {edtingLedger && <><EN>Save</EN><ZH>保存</ZH></>}
        </Button>
      </Modal.Footer>
    </Modal>
  </Translate>
}

function LedgerCollaboratorsModal({ledger, needReload, onHide, ...props}: ModalProps & {ledger?: Ledger, needReload: () => void}) {
  const permissions = React.useContext(PermissionsContext)
  const {translateText} = useUserLanguage()

  const {submitting: addSubmitting, changeHandler: addChangeHandler, submitHandler: addSubmitHandler} = useForm({
    email: '',
    role: 'write'
  }, async formData => {
    await fetchJSON(`/api/ledgers/${ledger?.id}/collaborators`, {
      method: 'POST',
      body: formData
    })

    needReload()
  })

  const [editingCollaborator, setEditingCollaborator] = React.useState<string | false>(false)

  const {submitting: editSubmitting, changed: editChanged, changeHandler: editChangeHandler, submitHandler: editSubmitHandler, setFields: setEditFields} = useForm({
    email: '',
    role: ''
  }, async formData => {
    await fetchJSON(`/api/ledgers/${ledger?.id}/collaborators/${formData.email}`, {
      method: 'PATCH',
      body: _.omit(formData, 'email')
    })

    needReload()
    setEditingCollaborator(false)
  })

  const removeCollaboratorAction = (email: string) => {
    return async () => {
      try {
        await fetchJSON(`/api/ledgers/${ledger?.id}/collaborators/${email}`, {
          method: 'DELETE'
        })

        needReload()
      } catch (err) {
        alertError(err)
      }
    }
  }

  const onHideHandler = () => {
    setEditingCollaborator(false)
    onHide?.()
  }

  return <Translate>
    <Modal {...props} show={!!ledger} onHide={onHideHandler} dialogClassName='modal-collaborators' fullscreen='md-down' centered backdrop='static'>
      <Modal.Header closeButton>
        <EN><Modal.Title>Collaborators of {ledger?.name}</Modal.Title></EN>
        <ZH><Modal.Title>{ledger?.name} 的协作者</Modal.Title></ZH>
      </Modal.Header>
      <Modal.Body>
        {!_.isEmpty(ledger?.collaborators) && <Table>
          <tbody>
            {ledger?.collaborators?.map( collaborator => {
              const editing = editingCollaborator === collaborator.email

              const state2icon = {
                invited: <BsHourglass />,
                joined: <BsCheckCircle />,
                leaved: <BsXCircle />
              }

              const state2text = {
                invited: {en: 'Invited', zh: '已邀请'},
                joined: {en: 'Joined', zh: '已加入'},
                leaved: {en: 'Leaved', zh: '已退出'}
              }

              return <tr key={collaborator.email}>
                <td>{collaborator.email}</td>
                <td>{state2icon[collaborator.state]} {translateText(state2text[collaborator.state])}</td>
                <td>
                  {!editing && translateText(role2text[collaborator.role])}
                  {editing && <OverlayTrigger trigger={!permissions.readonlyCollaborator ? ['hover', 'focus'] : []} overlay={<Tooltip><EN>Upgrade Pro to add readonly collaborator</EN><ZH>升级至 Pro 来添加只读协作者</ZH></Tooltip>}>
                    <Form.Select size='sm' disabled={!permissions.readonlyCollaborator} name='role' onChange={editChangeHandler} defaultValue={collaborator.role}>
                      <option value='write'><EN>Read & Write</EN><ZH>读写权限</ZH></option>
                      <option value='readonly'><EN>Readonly</EN><ZH>只读权限</ZH></option>
                    </Form.Select>
                  </OverlayTrigger>}
                </td>
                <td className='text-end'>
                  <ButtonGroup>
                    {!editing && <Button size='sm' onClick={() => {setEditingCollaborator(collaborator.email); setEditFields({email: collaborator.email})}}><EN>Edit</EN><ZH>编辑</ZH></Button>}
                    {editing && <Button size='sm' variant='success' disabled={editSubmitting || !editChanged} onClick={editSubmitHandler}><EN>Save</EN><ZH>保存</ZH></Button>}
                    <Button size='sm' variant='danger' onClick={confirmBefore(removeCollaboratorAction(collaborator.email))}><EN>Remove</EN><ZH>移除</ZH></Button>
                  </ButtonGroup>
                </td>
              </tr>
            })}
          </tbody>
        </Table>}
        {_.isEmpty(ledger?.collaborators) && <Alert variant='light'>
          <EN>Currently no collaborators</EN><ZH>目前没有协作者</ZH>
        </Alert>}
        <Form onSubmit={addSubmitHandler}>
          <InputGroup>
            <Form.Control name='email' type='email' placeholder='Email' onChange={addChangeHandler} />
            <OverlayTrigger trigger={!permissions.readonlyCollaborator ? ['hover', 'focus'] : []} overlay={<Tooltip><EN>Upgrade Pro to add readonly collaborator</EN><ZH>升级至 Pro 来添加只读协作者</ZH></Tooltip>}>
              <Form.Select disabled={!permissions.readonlyCollaborator} name='role' onChange={addChangeHandler} style={{maxWidth: '30%'}}>
                <option value='write'><EN>Read & Write</EN><ZH>读写权限</ZH></option>
                <option value='readonly'><EN>Readonly</EN><ZH>只读权限</ZH></option>
              </Form.Select>
            </OverlayTrigger>
            <Button type='submit' disabled={addSubmitting}><EN>Add</EN><ZH>添加</ZH></Button>
          </InputGroup>
          <InputGroup className='mt-2'>
            <Form.Text>
              <EN>
                Once added, the collaborator will receive an invitation on their dashboard, after accepted, the ledger will be accessible to them. <br />
                <em>Read & Write</em> means they can modify records on Fava or push via Git, and <em>Readonly</em> means they can only view reports on Fava or clone/pull via Git.
              </EN>
              <ZH>
                点击添加后，协作者会在他们的控制台收到邀请，接受邀请后即可访问账本。 <br />
                <strong>读写权限</strong> 意味着他们可以修改 Fava 上的帐目和使用 <code>git push</code>；<strong>只读权限</strong> 意味着他们只能查看 Fava 报告或使用 <code>git clone</code> 和 <code>git pull</code>。
              </ZH>
            </Form.Text>
          </InputGroup>
        </Form>
      </Modal.Body>
    </Modal>
  </Translate>
}
