import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import Documents from './website/Documents'
import Index from './website/Index'
import Login from './account/Login'
import Pricing from './website/Pricing'
import './App.css'

export default function App() {
  return <RouterProvider router={createBrowserRouter(createRoutesFromElements(
    <>
      <Route path='/' element={<Index />} />
      <Route path='/pricing' element={<Pricing />} />
      <Route path='/docs/:docName' element={<Documents />} />
      <Route path='/console/login' element={<Login />} />
      <Route path='/console/dashboard' element={<Dashboard />} />
    </>
  ))} />
}
