import {Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {BsClockHistory, BsCurrencyExchange, BsUiChecks, BsPencilSquare, BsDatabaseFillGear, BsHouseLockFill} from 'react-icons/bs'
import {EN, useUserLanguage, ZH} from '../lib/translate'
import Layout from '../Layout'
import screenshots from '../images/screenshots.png'
import {useLoggedUser} from '../lib/fetch'
import './Index.css'

export default function Index() {
  const username = useLoggedUser()

  return <Layout>
      <Row className='px-md-3 py-5 my-lg-5 text-center'>
        <h1 className='display-5 fw-bold text-body-emphasis'>HostedBeans</h1>
        <div className='col-lg-8 mx-auto'>
          <p className='lead mb-4'>
            <EN>Host your Beancount ledger with Git and Web UI access, enable collaboration on Plain Text Double-entry accounting</EN>
            <ZH>在线托管你的 Beancount 账本，通过 Git 和 Web UI 实现基于纯文本的复式记账协作</ZH>
          </p>
          <div className='d-grid gap-2 d-sm-flex px-sm-4 justify-content-sm-center'>
            {username && <Link to='/console/dashboard' className='btn btn-primary btn-lg px-4 gap-3'>
              <EN>Back to console as {username}</EN><ZH>回到控制台</ZH>
            </Link>}
            {!username && <Link to='/console/login' className='btn btn-primary btn-lg px-4 gap-3'>
              <EN>Login or Sign up</EN><ZH>登录或注册</ZH>
            </Link>}
            <Link to='/docs/getting-started' className='btn btn-outline-secondary btn-lg px-4 gap-3'>
              <EN>Getting Started</EN><ZH>快速入门</ZH>
            </Link>
          </div>
        </div>
      </Row>
      <Row className='row-cols-1 row-cols-lg-2 align-items-md-center g-5 px-md-3 py-5'>
        <div className='col d-flex flex-column align-items-start gap-2'>
          <h2 className='h3 fw-bold text-body-emphasis'><EN>Plain Text Double-entry bookkeeping</EN><ZH>纯文本复式记账</ZH></h2>
          <pre><code>{
`2013-06-02 * 'Bought some Apple stock'
  Assets:US:ETrade:Investment:AAPL    10 AAPL {450.72 USD}
  Expenses:Financial:Commissions      7.95 USD
  Assets:US:ETrade:Investment:Cash`}
          </code></pre>
          <h2 className='h3 fw-bold text-body-emphasis'><EN>Access your ledger via Git</EN><ZH>使用 Git 来访问你的账本</ZH></h2>
          <pre className='mb-1'><code>{
`$ git commit -a -m 'Bought some stock'
[master 0fbb65f] Bought some stock
 1 file changed, 5 insertions(+)

$ git push
Enumerating objects: 5, done.
Counting objects: 100% (5/5), done.
Delta compression using up to 8 threads
Compressing objects: 100% (3/3), done.
Writing objects: 100% (3/3), 314 bytes | 314.00 KiB/s, done.
Total 3 (delta 1), reused 0 (delta 0), pack-reused 0
To hostedbeans.io:ledgers/1
   e78be14..0fbb65f  master -> master`}
          </code></pre>
        </div>
        <div className='col'>
          <div className='row row-cols-1 row-cols-md-2 g-4'>
            <FeatureItem icon={<BsCurrencyExchange />} title={<><EN>Multi-currency</EN><ZH>全币种支持</ZH></>}>
              <EN>Manage multiple accounts and various currencies, track crypto or stock trades.</EN>
              <ZH>轻松管理多个账户、多个币种，追踪密码货币或证券交易。</ZH>
            </FeatureItem>
            <FeatureItem icon={<BsUiChecks />} title={<><EN>Auto-correction</EN><ZH>自动纠错</ZH></>}>
              <EN>Double-entry naturally has the ability to automatically correct errors.</EN>
              <ZH>复式记账追踪每一笔交易的收支流向，天然具有自动纠错的能力。</ZH>
            </FeatureItem>
            <FeatureItem icon={<BsPencilSquare />} title={<><EN>Local first</EN><ZH>本地编辑</ZH></>}>
              <EN>Leverage the power of plain text by using you own editor for accounting locally.</EN>
              <ZH>可以使用你常用的编辑器来编辑帐目，毕竟这才是纯文本记账的优势。</ZH>
            </FeatureItem>
            <FeatureItem icon={<BsDatabaseFillGear />} title={<><EN>Local plugins</EN><ZH>本地工具</ZH></>}>
              <EN>Use a lot of plugins locally from the Beancount community.</EN>
              <ZH>可以在本地使用社区中大量基于 Beancount 的工具。</ZH>
            </FeatureItem>
            <FeatureItem icon={<BsHouseLockFill />} title={<><EN>Control of data</EN><ZH>数据控制权</ZH></>}>
              <EN>Export all accounting data anytime via Git, never be locked into proprietary formats.</EN>
              <ZH>你可以随时使用 Git 导出完整的账本数据，避免被锁定到私有的数据格式。</ZH>
            </FeatureItem>
            <FeatureItem icon={<BsClockHistory />} title={<><EN>Time travel</EN><ZH>历史追溯</ZH></>}>
              <EN>View your entire history via Git and even revert to any point in time.</EN>
              <ZH>利用 Git 的版本控制能力，随时查看或回滚到历史版本的数据。</ZH>
            </FeatureItem>
          </div>
        </div>
      </Row>
      <Row className='flex-lg-row-reverse align-items-center g-5 px-md-3 py-5'>
        <div className='col-12 col-lg-6'>
          <img src={screenshots} className='d-block mx-lg-auto img-fluid' alt='Screenshots' width='700' height='500' />
        </div>
        <div className='col-lg-6'>
          <h2 className='fw-bold text-body-emphasis lh-1 mb-3'><EN>Seamless collaboration from anywhere</EN><ZH>随时随地，无缝协作</ZH></h2>
          <p className='lead'>
            <EN>View financial charts on <a target='_blank' rel='noreferrer' className='text-body-secondary' href='https://beancount.github.io/fava/'>Fava</a> from your browser or mobile device, anytime and anywhere. Invite your family or teammates to join your ledger, or even make the ledger public to everyone.</EN>
            <ZH>借助 <a target='_blank' rel='noreferrer' className='text-body-secondary' href='https://beancount.github.io/fava/'>Fava</a> 提供的 Web UI，你可以在浏览器或移动设备上查看图表或记账。HostedBeans 支持为每个账本添加多名协作者并管理他们的权限，甚至或直接将整个账本设置为公开，协作者也可以通过网页或 Git 读写账本。</ZH>
          </p>
        </div>
      </Row>
  </Layout>
}

function FeatureItem({icon, title, children}: {icon: React.ReactNode, title: React.ReactNode, children: React.ReactNode}) {
  const {translate} = useUserLanguage()

  return <div className='col d-flex flex-column gap-2'>
    <div className='d-flex flex-row'>
      <div className='feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3 me-2'>
        {icon}
      </div>
      <h4 className='fw-semibold my-auto text-body-emphasis'>{translate(title)}</h4>
    </div>
    <p className='text-body-secondary'>{children}</p>
  </div>
}
