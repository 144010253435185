import React from 'react'
import {alertError} from './utils'

export function useForm<T>(defaults: T, onSubmit: (formData: T) => Promise<void>) {
  const [changed, setChanged] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [formData, setFormData] = React.useState(defaults)

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setChanged(true)
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await onSubmit(formData)
      setChanged(false)
    } catch (err) {
      alertError(err)
    } finally {
      setSubmitting(false)
    }
  }

  return {
    formData, changed, submitting,
    changeHandler, submitHandler,

    setFields(fields: Partial<T>) {
      setFormData({...formData, ...fields})
    }
  }
}
