import {createResourceHook} from '@leancloud/use-resource'
import {useCookies} from 'react-cookie'
import Cookies from 'universal-cookie'

const cookies = new Cookies('', {path: '/'})

export const useFetch = createResourceHook(fetchJSON)

export function useLoggedUser() {
  const [cookies] = useCookies(['sessionToken'])

  if (typeof localStorage !== 'undefined') {
    const username = localStorage.getItem('accountName')
    return cookies['sessionToken'] ? username : username
  } else {
    return cookies['sessionToken']
  }
}

export interface FetchJSONOptions extends Omit<RequestInit, 'headers' | 'body'> {
  headers?: Record<string, string>
  body?: Record<string, any>
}

export function fetchJSON<T>(url: string, options: FetchJSONOptions = {}): Promise<T> {
  const headers = options.headers || {}

  const fetchOptions: RequestInit = {headers}

  if (options.body) {
    headers['Content-Type'] = 'application/json'
    fetchOptions.body = JSON.stringify(options.body)
  }

  const sessionToken = cookies.get('sessionToken')

  if (sessionToken) {
    headers['Authorization'] = sessionToken
  }

  return fetch(url, Object.assign({}, options, fetchOptions)).then( res => {
    return res.text().then( body => {
      var err;

      if (res.ok) {
        try {
          return JSON.parse(body)
        } catch (err) {
          return body
        }
      } else {
        const reqDesc = `[${options.method ?? 'GET'} ${url}]`

        try {
          err = new Error(`${res.status}: ${JSON.parse(body).error} ${reqDesc}`)
        } catch (e) {
          err = new Error(`${res.status}: ${res.statusText} ${reqDesc}`)
        }

        throw Object.assign(err, {res, body})
      }
    })
  })
}
