import React from 'react'
import {Alert, Button} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'

export function ErrorGuard({err, children}: {err?: Error, children: React.ReactNode}) {
  const navigate = useNavigate()

  if (err && err.message.startsWith('401: session not found')) {
    return <Alert variant='danger'>
      <p>{err.message}</p>
      <hr />
      <div className='d-flex'>
        <Button className='float-end' variant='outline-danger' onClick={() => navigate('/console/login')}>
          Login again
        </Button>
      </div>
    </Alert>
  } else if (err) {
    return <Alert variant='danger'>{err.message}</Alert>
  } else {
    return <>{children}</>
  }
}

// https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34
export function Scrollable({children}: {children: React.ReactNode}) {
  const {hash} = useLocation()
  const scrolledRef = React.useRef(false)
  const hashRef = React.useRef(hash)

  React.useEffect(() => {
    if (hash) {
      if (hashRef.current !== hash) {
        hashRef.current = hash
        scrolledRef.current = false
      }

      if (!scrolledRef.current) {
        const element = document.getElementById(hash.replace('#', ''))
        if (element) {
          element.scrollIntoView()
          scrolledRef.current = true
        }
      }
    }
  })

  return <>{children}</>
}
