import {rest} from 'msw'

export const handlers = [
  rest.get('/api/accounts/self', (req, res, ctx) => {
    return res(ctx.json({id: 1, name: 'Ziting', plan: 'free', email: "jysperm@example.com", sshKeys: ''}))
  }),
  rest.get('/api/ledgers', (req, res, ctx) => {
    return res(ctx.json([
      {id: 1, name: 'My Ledger', visibility: 'private', ownerId: 1, collaborators: [
        {ledgerId: 1, accountId: 3, email: '3@example.com', state: 'joined', role: 'write'}
      ]}
    ]))
  }),
  rest.get('/api/collaborations', (req, res, ctx) => {
    return res(ctx.json([
      {ledgerId: 2, ledgerName: 'Another Ledger', ownerEmail: 'hello@example.com', state: 'invited', role: 'write'}
    ]))
  })
]
