import _ from 'lodash'
import React, {ReactNode, JSXElementConstructor, useContext} from 'react'
import {useCookies} from 'react-cookie'
import {useSearchParams} from 'react-router-dom'

/*
 * Language override priority:
 * - React Context (LanguageContext)
 * - User Agent (lang/*)
 * - Query Srting (lang)
 * - Cookie (language)
 * - Accept-Language (navigator.languages)
 * - defaultLanguage (en)
 */

const defaultLanguage = 'en'
const LanguageContext = React.createContext<string | undefined>(undefined)

export function LanguageProvider({children, language}: {children: ReactNode, language: string}) {
  return <LanguageContext.Provider value={language}>
    {children}
  </LanguageContext.Provider>
}

export function useUserLanguage() {
  const [cookies, setCookie] = useCookies(['language'])
  const [searchParams, setSearchParams] = useSearchParams()
  const overrideLanguage = useOverrideLanguage()

  const preferredLanguage = overrideLanguage || cookies['language']
  const userLanguages = getUserLanguages(preferredLanguage)

  return {
    language: userLanguages[0] || defaultLanguage,
    preferredLanguage: preferredLanguage,

    setLanguage(language: string) {
      searchParams.delete('lang')
      setSearchParams(searchParams)
      setCookie('language', language, {path: '/', sameSite: 'strict'})
    },

    translate(children: ReactNode): JSX.Element {
      return <>{mapChildren(children, userLanguages)}</>
    },

    translateText<T = string>(texts: Record<string, T>): T {
      return texts[resolveLanguage(Object.keys(texts), userLanguages)]
    },

    resolveLanguage(availableLanguages: string[]) {
      return resolveLanguage(availableLanguages, userLanguages)
    }
  }
}

export function Translate({children}: {children: ReactNode}): JSX.Element {
  const [cookies] = useCookies(['language'])
  const overrideLanguage = useOverrideLanguage()

  return <>{mapChildren(children, getUserLanguages(overrideLanguage || cookies['language']))}</>
}

function mapChildren(children: ReactNode, userLanguages: string[]): ReactNode {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const languages: string[] = _.compact(child.props.children?.map && child.props.children.map( (c: any) => {
        if (translations.includes(c?.type)) {
          return c.type.language
        } else {
          return null
        }
      }))

      if (languages.length) {
        const pickedLanguage = resolveLanguage(languages, userLanguages)

        return React.cloneElement(child, child.props, ..._.castArray(mapChildren(_.filter(child.props.children, (c: any) => {
          return !translations.includes(c?.type) || c.type.language === pickedLanguage
        }), userLanguages)))
      } else if (child.props.children) {
        return React.cloneElement(child, child.props, ..._.castArray(mapChildren(child.props.children, userLanguages)))
      } else {
        return child
      }
    } else {
      return child
    }
  })
}

const translations: JSXElementConstructor<any>[] = []

export function translationFactory(language: string) {
  const translation = function({children}: {children: ReactNode}) {
    return <span>{children}</span>
  }

  translation.language = language

  translations.push(translation)

  return translation
}

export const EN = translationFactory('en')
export const ZH = translationFactory('zh')

function useOverrideLanguage() {
  let overrideLanguage = useContext(LanguageContext)
  const [searchParams] = useSearchParams()

  if (searchParams.has('lang')) {
    overrideLanguage = searchParams.get('lang') || ''
  } else if (typeof navigator !== 'undefined') {
    const matched = navigator.userAgent.match(/lang\/(.+)/)

    if (matched) {
      overrideLanguage = matched[1]
    }
  }

  return overrideLanguage
}

function getUserLanguages(preferredLanguage?: string): string[] {
  return _.castArray(preferredLanguage || navigator.languages || navigator.language || '')
}

function resolveLanguage(availableLanguages: string[], userLanguages: string[], fallback = defaultLanguage) {
  for (const userLanguage of userLanguages) {
    if (availableLanguages.includes(userLanguage)) {
      return userLanguage
    }
  }

  for (const userLanguage of userLanguages) {
    const compatible = availableLanguages.find( availableLanguage => {
      return userLanguage.split('-', 1)[0] === availableLanguage.split('-', 1)[0]
    })

    if (compatible) {
      return compatible
    }
  }

  return fallback
}
