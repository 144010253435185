import React from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (JSON.parse(process.env.REACT_APP_MOCK || 'false')) {
  require('./mocks/browser').worker.start()
}

const rootElement = document.getElementById('root') as HTMLElement

createRoot(rootElement).render(<React.StrictMode><App /></React.StrictMode>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
