export function alertError(err: any) {
  console.error(err)
  alert(err?.message)
}

export function confirmBefore<T extends Array<any>, R>(func: (...args: T) => R, message = 'Sure?') {
  return (...args: T) => {
    if (window.confirm(message)) {
      return func(...args)
    }
  }
}
