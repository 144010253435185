import {Account, Ledger} from './models'

export type Permissions = Partial<ReturnType<typeof getPermissions>>

export function getPermissions(account?: Account, ledgers?: Ledger[]) {
  return {
    createLedger: account?.plan !== 'free',
    readonlyCollaborator: account?.plan !== 'free'
  }
}
