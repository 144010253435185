import {Row, Col} from 'react-bootstrap'
import {BsCheck, BsX, BsEnvelopeAtFill, BsTelegram, BsTwitter} from 'react-icons/bs'
import Layout from '../Layout'
import {EN, ZH, useUserLanguage} from '../lib/translate'

export default function Pricing() {
  const {translateText} = useUserLanguage()

  return <Layout title={translateText({en: 'Pricing', zh: '价格方案'})}>
    <Row className='justify-content-center'>
      <Col className='col col-xl-10'>
        <Row>
          <div className='w-75 p-3 pb-md-4 mx-auto text-center'>
            <h1 className='display-4 fw-normal text-body-emphasis'><EN>Pricing</EN><ZH>价格方案</ZH></h1>
          </div>
        </Row>
        <Row className='row-cols-1 row-cols-md-3 mb-3 text-center'>
          <Col>
            <div className='card mb-4 rounded-3 shadow-sm'>
              <div className='card-header py-3'>
                <h4 className='my-0 fw-normal'>Free</h4>
              </div>
              <div className='card-body'>
                <h2 className='card-title pricing-card-title'>
                  $0<small className='text-body-secondary fw-light'>/mo</small>
                </h2>
                <p className='text-body-secondary'>
                  <EN>Free for individuals just starting to use Beancount</EN>
                  <ZH>适用于刚接触 Beancount 的个人用户</ZH>
                </p>
                <ul className='list-unstyled my-3'>
                  <li><EN>5 MB storage</EN><ZH>5 MB 存储空间</ZH></li>
                  <li><EN>1 ledger</EN><ZH>1 个账本</ZH></li>
                  <li><EN>1 collaborator</EN><ZH>1 名协作者</ZH></li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className='card mb-4 rounded-3 shadow-sm border-primary'>
              <div className='card-header py-3 border-primary'>
                <h4 className='my-0 fw-normal'>Pro</h4>
              </div>
              <div className='card-body'>
                <h2 className='card-title pricing-card-title'>
                  <del>$5</del><small className='text-body-secondary fw-light'>/mo</small>
                </h2>
                <p className='text-body-secondary'>
                  <EN>For family, small business or organization</EN>
                  <ZH>适用于家庭、小型公司或组织</ZH>
                </p>
                <ul className='list-unstyled my-3'>
                  <li><EN>50 MB storage</EN><ZH>50 MB 存储空间</ZH></li>
                  <li><EN>2 ledgers</EN><ZH>2 个账本</ZH></li>
                  <li><EN>4 collaborators per ledger</EN><ZH>每个账本 4 名协作者</ZH></li>
                </ul>
                <p style={{marginLeft: '-1rem', marginRight: '-1rem', marginBottom: '0.25rem'}}>
                  <mark>
                    <EN>Special offer: Contact us to get Pro for free</EN>
                    <ZH>限时活动: 联系我们免费获得专业版</ZH>
                  </mark>
                </p>
                <p>
                  <a className='btn btn-sm btn-outline-primary m-1' target='_blank' rel='noreferrer' href='mailto:feedback@hostedbeans.io'><BsEnvelopeAtFill /> Email</a>
                  <a className='btn btn-sm btn-outline-primary m-1' target='_blank' rel='noreferrer' href='https://t.me/jysperm'><BsTelegram /> Telegram</a>
                  <a className='btn btn-sm btn-outline-primary m-1' target='_blank' rel='noreferrer' href='https://twitter.com/jysperm'><BsTwitter /> Twitter</a>
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div className='card mb-4 rounded-3 shadow-sm'>
              <div className='card-header py-3'>
                <h4 className='my-0 fw-normal'><EN>Enterprise</EN><ZH>定制版</ZH></h4>
              </div>
              <div className='card-body'>
                <p className='text-body-secondary'>
                  <EN>Contact us to customize HostedBeans features according to your needs, such as unlimited ledger size and collaborators</EN>
                  <ZH>定制 HostedBeans 的功能来适配你的需求，如无限制的存储空间和协作者数量</ZH>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <h2 className='display-6 text-center mb-4'><EN>Compare plans</EN><ZH>功能差异对比</ZH></h2>
          <div className='table-responsive'>
            <table className='table text-center'>
              <thead>
                <tr>
                  <th style={{width: '34%'}}></th>
                  <th style={{width: '22%'}}><EN>Free</EN><ZH>Free</ZH></th>
                  <th style={{width: '22%'}}><EN>Pro</EN><ZH>Pro</ZH></th>
                  <th style={{width: '22%'}}><EN>Enterprise</EN><ZH>定制版</ZH></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope='row' className='text-start'><EN>Private ledger</EN><ZH>私有账本</ZH></th>
                  <td><EN>Only 1</EN><ZH>1 个</ZH></td>
                  <td><EN>Up to 2</EN><ZH>最多 2 个</ZH></td>
                  <td><EN>Unlimited</EN><ZH>无限</ZH></td>
                </tr>
                <tr>
                  <th scope='row' className='text-start'><EN>Public ledger</EN><ZH>公开账本</ZH></th>
                  <td><BsX /></td>
                  <td><EN>Up to 2</EN><ZH>最多 2 个</ZH></td>
                  <td><EN>Unlimited</EN><ZH>无限</ZH></td>
                </tr>
                <tr>
                  <th scope='row' className='text-start'><EN>Ledger size</EN><ZH>账本存储空间</ZH></th>
                  <td>5 MB</td>
                  <td><EN>50 MB per ledger</EN><ZH>每个账本 50 MB</ZH></td>
                  <td><EN>Unlimited</EN><ZH>无限</ZH></td>
                </tr>
                <tr>
                  <th scope='row' className='text-start'><EN>Web UI running limit</EN><ZH>Web UI 运行时间</ZH></th>
                  <td><EN>4 hours/day</EN><ZH>每天 4 小时</ZH></td>
                  <td><EN>Unlimited</EN><ZH>无限</ZH></td>
                  <td><EN>Unlimited</EN><ZH>无限</ZH></td>
                </tr>
                <tr>
                  <th scope='row' className='text-start'><EN>Collaborators</EN><ZH>协作者</ZH></th>
                  <td><EN>Only 1</EN><ZH>1 名</ZH></td>
                  <td><EN>4 per ledger</EN><ZH>每个账本 4 名</ZH></td>
                  <td><EN>Unlimited</EN><ZH>无限</ZH></td>
                </tr>
                <tr>
                  <th scope='row' className='text-start'><EN>Readonly collaborators</EN><ZH>只读协作者</ZH></th>
                  <td><BsX /></td>
                  <td><BsCheck /></td>
                  <td><BsCheck /></td>
                </tr>
                <tr>
                  <th scope='row' className='text-start'><EN>Priority support</EN><ZH>优先技术支持</ZH></th>
                  <td><BsX /></td>
                  <td><BsCheck /></td>
                  <td><BsCheck /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Col>
    </Row>
  </Layout>
}
