import React, {Suspense} from 'react'
import {Col, Form, ListGroup, Row, Spinner} from 'react-bootstrap'
import {NavLink, useParams} from 'react-router-dom'
import Layout from '../Layout'
import {useFetch} from '../lib/fetch'
import {Ledger, LedgerCollaboration} from '../lib/models'
import {EN, ZH, useUserLanguage} from '../lib/translate'
import './Documents.css'
import {Scrollable} from '../lib/components'
import {useAsync, useLocalStorage} from 'react-use'
import _ from 'lodash'
import {loadDocsComponent} from '../lib/mdx'

export default function Documents(props?: {docName?: string}) {
  const params = useParams()

  const {resolveLanguage} = useUserLanguage()
  const {selectedLedgerDetail} = useSelectedLedger()

  const docName = props?.docName || params.docName
  const language = resolveLanguage(['en', 'zh'])

  const DocModule = loadDocsComponent(`${docName}.${language}.mdx`)

  const Doc = React.useMemo(() => {
    return React.lazy(() => DocModule)
  }, [docName, language])

  const {value: docMeta} = useAsync(async () => {
    return _.pick(await DocModule, 'title', 'description', 'article')
  }, [docName, language])

  return <Layout title={docMeta?.title} description={docMeta?.description} article={docMeta?.article}>
    <Row>
      <Col xs={{order: 'last'}} lg={{order: 'first', span: 3}}>
        <ListGroup>
          <NavLink to='/docs/getting-started' className='list-group-item list-group-item-action'><EN>Getting Started</EN><ZH>快速入门</ZH></NavLink>
          <NavLink to='/docs/manual' className='list-group-item list-group-item-action'><EN>User Manual</EN><ZH>用户手册</ZH></NavLink>
          <NavLink to='/docs/faq' className='list-group-item list-group-item-action'><EN>FAQs</EN><ZH>常见问题</ZH></NavLink>
        </ListGroup>
        <ListGroup className='mt-3'>
          <NavLink to='/docs/opensource' className='list-group-item list-group-item-action'><EN>Credits to Open Source</EN><ZH>开源项目致谢</ZH></NavLink>
        </ListGroup>
        <ListGroup className='mt-3'>
          <NavLink to='/docs/introducing' className='list-group-item list-group-item-action'><EN>Introducing HostedBeans</EN><ZH>发布 HostedBeans</ZH></NavLink>
        </ListGroup>
      </Col>
      <Col lg='9' className='docs-content'>
        <Suspense fallback={<Spinner animation='border' />}>
          <Scrollable>
            <Doc ledger={selectedLedgerDetail} />
          </Scrollable>
        </Suspense>
      </Col>
    </Row>
  </Layout>
}

export function LedgerSelector() {
  const {selectedLedgerId, setSelectedLedger} = useSelectedLedger()
  const [ledgers] = useFetch<Ledger[]>(['/api/ledgers'])
  const [collaborations] = useFetch<LedgerCollaboration[]>(['/api/collaborations'])

  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLedger(_.find(ledgers, {id: parseInt(e.target.value)})!)
  }

  React.useEffect(() => {
    if (!selectedLedgerId && _.isArray(ledgers)) {
      setSelectedLedger(ledgers[0])
    }
  }, [ledgers?.[0]])

  if (!_.isArray(ledgers)) {
    return
  }

  return <Form.Group className='mb-2'>
    <Form.Select size='sm' style={{width: 'auto'}} onChange={onChangeHandler} value={selectedLedgerId}>
      {ledgers?.map( ledger => {
        const collaboration = _.find(collaborations, {ledgerId: ledger.id})

        return <option key={ledger.id} value={ledger.id}>{ledger.name}{collaboration ? ` (Shared by ${collaboration.ownerEmail})` : ''}</option>
      })}
    </Form.Select>
    <Form.Text muted>Selected ledger will appear in examples</Form.Text>
  </Form.Group>
}

export function useSelectedLedger() {
  let [selectedLedgerId, setSelectedLedgerId] = useLocalStorage<string>('selectedLedger', '')
  const [selectedLedgerDetailJSON, setSelectedLedgerDetail] = useLocalStorage<string>('selectedLedgerDetail', '')

  let selectedLedgerDetail: Partial<Ledger & {gitUrl: string}> = {}

  try {
    selectedLedgerDetail = JSON.parse(selectedLedgerDetailJSON || '')
  } catch {
    selectedLedgerId = ''
    selectedLedgerDetail = {
      id: 1,
      name: 'Your Ledger',
      gitUrl: 'git@hostedbeans.io:ledgers/1'
    }
  }

  return {
    selectedLedgerId,
    selectedLedgerDetail,

    setSelectedLedger(ledger: Ledger) {
      setSelectedLedgerId(ledger.id.toString())
      setSelectedLedgerDetail(JSON.stringify({
        id: ledger.id,
        name: ledger.name,
        gitUrl: `git@hostedbeans.io:ledgers/${ledger.id}`
      }))
    }
  }
}
